
import { api } from "@/api/api";
import { ApiCreateMemberOrganizationFeatureSettingDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, defineComponent, onMounted, ref } from "@vue/composition-api";
export default defineComponent({
  name: "MemberOrganizationFeatureSettingsModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: false,
    },
  },
  emits: ["close", "update"],
  setup(_, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const orgId = ref(+route.params.mainOfficeId);
    const memberOrgSettings = ref<ApiCreateMemberOrganizationFeatureSettingDto>({
      hasMultiLevelMemberOrganization: false,
      canUseSubOrganizer: false,
      usePreApprovalForCourses: false,
      bypassEnrollmentDeadlineInGuestPortal: false,
    });

    const getFeatureSettings = async () => {
      const response = await api.organization.getMemberOrganizationFeatureSettings(orgId.value);
      memberOrgSettings.value = response.data;
    };

    const onSubmit = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const payload: ApiCreateMemberOrganizationFeatureSettingDto = {
          hasMultiLevelMemberOrganization: memberOrgSettings.value.hasMultiLevelMemberOrganization,
          canUseSubOrganizer: memberOrgSettings.value.canUseSubOrganizer,
          usePreApprovalForCourses: memberOrgSettings.value.usePreApprovalForCourses,
          bypassEnrollmentDeadlineInGuestPortal: memberOrgSettings.value.bypassEnrollmentDeadlineInGuestPortal,
        };

        api.organization.updateMemberOrganizationFeatureSettings(orgId.value, payload);
        openNotification(store, NotificationItemType.Success, "Innstillinger oppdatert");
        emit("close");
      });
    };

    onMounted(() => {
      getFeatureSettings();
    });

    return {
      onSubmit,
      memberOrgSettings,
    };
  },
});
