var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('section', [_c('v-row', [_c('v-col', [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Kan bruke underarrangør"
    },
    model: {
      value: _vm.memberOrgSettings.canUseSubOrganizer,
      callback: function callback($$v) {
        _vm.$set(_vm.memberOrgSettings, "canUseSubOrganizer", $$v);
      },
      expression: "memberOrgSettings.canUseSubOrganizer"
    }
  }), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Bypass påmeldingsfrist i gjestepåmelding"
    },
    model: {
      value: _vm.memberOrgSettings.bypassEnrollmentDeadlineInGuestPortal,
      callback: function callback($$v) {
        _vm.$set(_vm.memberOrgSettings, "bypassEnrollmentDeadlineInGuestPortal", $$v);
      },
      expression: "memberOrgSettings.bypassEnrollmentDeadlineInGuestPortal"
    }
  }), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Flerenivåer"
    },
    model: {
      value: _vm.memberOrgSettings.hasMultiLevelMemberOrganization,
      callback: function callback($$v) {
        _vm.$set(_vm.memberOrgSettings, "hasMultiLevelMemberOrganization", $$v);
      },
      expression: "memberOrgSettings.hasMultiLevelMemberOrganization"
    }
  }), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Forhåndsgodkjenning"
    },
    model: {
      value: _vm.memberOrgSettings.usePreApprovalForCourses,
      callback: function callback($$v) {
        _vm.$set(_vm.memberOrgSettings, "usePreApprovalForCourses", $$v);
      },
      expression: "memberOrgSettings.usePreApprovalForCourses"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }